// Email.scss

.email-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #01f28d;
    margin-left: 10vw;
    margin-right: 10vw;
    border-radius: 10px;

    @media screen and (max-width: 767px) {
        margin-left: 15vw;
        margin-right: 15vw;
    }


    form {
        max-width: 600px;
        width: 100%;
    }

    .input-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        padding: 10px;

        label {
            margin-bottom: 0.5rem;
        }

        input,
        textarea {
            padding: 0.5rem;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 1rem;
            resize: vertical;
        }
    }

    button {
        padding: 0.75rem 1.5rem;
        margin-bottom: 10px;
        background-color: #0B1926;
        color: #fff;
        font-size: 1rem;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #00c479;
        }
    }

    .message-status {
        width: 100%;
        text-align: center;
        margin-top: 10px;
        font-size: 1.2rem;
        color: #0B1926;
    }
}