.testimonials {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    h1 {
        margin-bottom: 2rem;
        color: white;
    }

    span {
        color: #01F28D;
    }

    .testimonial-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        width: 90%;

        .testimonial {
            flex: 1 1 calc(100% / 3 - 1rem);
            position: relative;
            box-sizing: border-box;
            background-color: #0b1926;
            border-radius: 5px;
            padding: 2rem;
            color: white;
            text-align: center;

            .testimonial-stars {
                font-size: 1.5em;
                color: #FFD700; // Gold color for the stars
                margin-bottom: 1em;
            }

            .testimonial-title {
                font-size: 1.2em;
                font-weight: bold;
                margin-bottom: 1em;
            }

            .testimonial-description {
                font-size: 1em;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .testimonial-container .testimonial {
            flex: 1 1 calc(100% / 2 - 1rem);
        }
    }

    @media screen and (max-width: 767px) {
        .testimonial-container .testimonial {
            flex: 1 1 100%;
        }
    }
}