.product {
    width: 80vw;
    height: 33vw;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    perspective: 1000px;
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);

    @media screen and (max-width: 767px) {
        height: 50vh;
        width: 75vw;
    }
}

.card {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.product:hover .card {
    transform: rotateY(180deg);
}

.front,
.back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.front {
    background: #01f28d;
    font-size: 50px;
    font-weight: bold;

    @media screen and (max-width: 767px) {
        font-size: 30px;

    }

}

.back {
    background: #01f28d;
    transform: rotateY(180deg);
    font-size: 20px;
    font-weight: bold;

    @media screen and (max-width:767px) {
        font-size: 15px;
    }

    p {
        margin: 0 50px;
    }
}