.people-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 50px;
    margin-left: 50px;
    padding: 30px;
    background-color: #01f28d;
    border-radius: 10px;

    .person {
        flex-basis: calc(100% / 3 - 20px);
        text-align: center;
        margin: 10px;
        box-sizing: border-box;

        img {
            width: 20vw;
            /* adjust as needed */
            height: 50vh;
            /* adjust as needed */
            object-fit: cover;
            /* this will prevent the image from being squashed or stretched */
            border-radius: 10px;

            @media screen and (max-width: 767px) {
                height: 30vh;
                width: 55vw;
            }
        }

        h3 {
            margin: 10px 0;
        }
    }
}

@media (max-width: 768px) {
    .people-section {
        .person {
            flex-basis: 100%;
        }
    }
}


.youtube-verified {
    width: 20%;
    padding: 2rem;
    margin-top: 50px;
    background-color: #0b1926;
    color: white;
    border-radius: 5px;

    @media screen and (max-width: 767px) {
        width: 60%;
    }

    img {
        width: 100%;

        @media screen and (max-width:767px) {
            width: 100%;
        }
    }


}