.achievementSection {
    h1 {
        color: white;
    }

    span {
        color: #01F28D;
    }
}

.achievements-container {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #0b1926;
    height: 100vh;
}

.arrow {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    z-index: 2;
}

.achievements {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 80%;
    position: relative;
}

.achievement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;

    .white-container {
        position: relative; // make it a positioned element
        background-color: #01F28D;
        width: 300px;
        height: 300px;
        border-radius: 5px;


        h1 {
            color: black;
        }

        .description {
            position: absolute;
            bottom: 0;
            border-radius: 5px;
            margin-left: 13px;
            height: 140px;
            padding: 1rem;
            left: 0;
            right: 0;
            width: calc(100% - 2rem); // subtract padding
            box-sizing: border-box;
            background-color: rgba(11, 25, 38, 0.6);

            p {
                margin: 0;
                color: white;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .white-container .description {
            margin-left: 40px;
            width: calc(80% - 2rem); // subtract padding
            padding: 1rem;
            /* Adjust width for smaller screens */
        }
    }
}

.left-arrow {
    position: absolute;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
}

.right-arrow {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
    .achievements-container {
        height: 70vh;
    }

    .achievement img {
        width: 60%;
        height: auto;
        object-fit: cover;
    }
}