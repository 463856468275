/* Values.scss */

.values-section {
    h1 {
        text-align: center;
        margin-bottom: 2rem;
        color: white;
    }

    span {
        color: #01F28D;
    }

    .values-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .value-pair {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 2rem;

            &.row-reverse {
                flex-direction: row-reverse;
            }

            .value-image,
            .value-description {
                width: 45%; // adjust as needed
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column; // Add this line
            }

            .value-image {
                border-radius: 5px;
                margin: 0 50px;
                background-color: #01F28D;

                @media screen and (max-width: 767px) {
                    margin: 0;
                    width: 20%;
                }
            }

            .value-description {
                h1 {
                    margin-bottom: 1rem; // Add some space between the title and description
                }

                p {
                    font-size: 1.2rem;
                    text-align: center;
                    max-width: 300px;
                    color: white;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .values-section {
        padding: 30px;

        .values-grid {
            .value-pair {
                flex-direction: column !important;

                .value-image,
                .value-description {
                    width: 100%;
                }
            }
        }
    }
}