.nav-item.active {
    background-color: #01F28D;
    border-radius: 5px;
    color: black;

    a:hover {
        color: black;
    }
}

.nav-item.active a:hover {
    color: black !important;
}

.logo-container {
    img {
        width: 70%;
    }
}


.navbar {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: relative;
    margin-left: 10vw;
    margin-right: 10vw;

    h1 {
        color: #01F28D;
    }

    .menu-toggle {
        display: none;
        cursor: pointer;
        border: none;
        background: none;
        padding: 0;
        justify-content: flex-end;
        margin-left: 120px;

        span {
            display: block;
            width: 25px;
            height: 3px;
            background-color: #01F28D;
            margin: 5px 0;
        }

        @media screen and (max-width: 767px) {
            display: block;
        }
    }

    .bar {
        display: flex;
        align-items: center;
        justify-content: right;
        flex-grow: 1;
    }

    .navbar-nav {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;

        @media screen and (max-width: 767px) {
            position: fixed;
            top: 0;
            left: -100%;
            width: 80%;
            height: 100%;
            background-color: #01F28D;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: left 0.3s ease;
        }
    }



    .navbar-nav {
        border-radius: 10px;
        height: 25px;
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;

        @media screen and (max-width: 767px) {
            position: fixed;
            top: 0;
            left: -100%;
            width: 80%;
            height: 100%;
            background-color: #01F28D;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: left 0.3s ease;
        }
    }

    .navbar-nav.open {
        left: 0;
        z-index: 10;
    }

    .nav-item {
        margin-left: 1rem;
        height: 25px;
        width: 80px;

        // Style the link
        a {
            color: white; // Set link color
            text-decoration: none; // Remove underline
            transition: color 0.3s ease; // Add transition for color change on hover
        }

        // Style the link on hover
        a:hover {
            color: #01F28D; // Set link color on hover
        }

        @media screen and (max-width: 767px) {
            margin: 1rem 0;
        }
    }
}