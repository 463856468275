.hero-section {
    width: 90vw;
    text-align: center;
    margin: 0 auto;
    color: white;
    height: 30vh;

    & span {
        color: #01F28D;
    }

    @media screen and (max-width: 767px) {
        width: 90vw;
        text-align: center;

        .hero-under-content {
            flex-direction: column;
            margin-bottom: 10px;

            input {
                margin-bottom: 10px;
            }
        }
    }

    .hero-under-content {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        input[type='email'] {
            border-radius: 5px;
            border: none;
            padding: 0.5rem;
            width: 40%;
            margin-right: 10px;
        }

        button {
            border-radius: 5px;
            border: none;
            background-color: #01f28d;
            color: #fff;
            padding: 0.5rem 1.5rem;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #0c7b43;
            }
        }
    }
}