// Faq.scss
.faq-container {
    width: 80%;
    margin-left: 10vw;
    margin-right: 10vw;

    @media screen and (max-width: 767px) {
        margin-left: 15vw;
        margin-right: 15vw;
        width: 70%;
    }

    h1 {
        color: white;
    }
}

.faq-item {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
}

.arrow {
    margin-left: 0.5rem;
    color: #01F28D;
}

.faq-answer {
    overflow: hidden;

    p {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
        color: white;
    }
}

.faq-divider {
    height: 1.5px;
    background-color: #01F28D;
    margin-top: 1rem;
}