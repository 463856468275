.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;

    @media screen and (max-width: 767px) {
        margin-top: 6rem;
    }

    .icon-under-section {
        color: #01F28D;
    }

    .single-feature {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1.5rem;
        text-align: center;
        max-width: 25rem;

        .icon {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: #01f28e75;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .feature-icon {
            color: #01F28D;
            font-size: 2rem;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }


        h3 {
            color: #01F28D;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }

        p {
            color: white;
            font-size: 1.2rem;
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;

        .single-feature {
            max-width: none;
        }
    }
}