.footer-section {
    margin: 10vh 10vw;
    color: white;

    .privacy-link {
        text-decoration: none;
        color: white;
        margin-left: 10px;

        &:hover {
            text-decoration: underline;
        }
    }
}