.social-icons {
    position: fixed;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    z-index: 10;

    a {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.5rem;
        color: #01F28D;

        &:hover {
            color: #01F28D;
        }
    }
}